import React from "react";
import * as ReactDOM from "react-dom/client";
import reactToWebcomponent from "react-to-webcomponent";
import PropTypes from "prop-types";
import Widget from "./widget";
import ChannelWidget from "./channelWidget";

Widget.propTypes = {
  token : PropTypes.string
};

ChannelWidget.propTypes = {
  token : PropTypes.string,
  type  : PropTypes.string
};

customElements.define("vf-widget", reactToWebcomponent(Widget, React, ReactDOM));
customElements.define("vfc-widget", reactToWebcomponent(ChannelWidget, React, ReactDOM));
