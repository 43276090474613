import { useState, useEffect, lazy, Suspense } from "react";
import VfLogo from './utils/vfLogo';
import "./widget.css";

const ChannelWidget = ({ token, type }) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [Component, setComponent] = useState();

    const getData = async () =>{
        console.log(`Pulling data from: ${token}`);
        // const res = await fetch(`http://localhost:3002/widgets/channel/${token}`);
        const res = await fetch(`https://www.videoflow.io/widgets/channel/${token}`);
        if (res.ok) {
            const resData = await res.json();
            console.log(resData);
            setData(resData);

            const w = lazy(()=> import(`./widgets/${type}/${type}.js`));
            setComponent(w);

            setLoading(false);
        } else {
            setLoading(false);
            setError("Content is not available");
        }
    }

    useEffect(()=>{
        getData();
    },[]);

    if(loading){
        return (
            <div className="w-full h-full flex justify-center items-center">
              <div className="bg-black/90 p-5 rounded-md">
                <span className="text-white animate-pulse">Loading...</span>
              </div>
            </div>
        );
    }else{
    return(
        <Suspense fallback={null}>
            <Component data={data} />
        </Suspense>
        );
    }
}

export default ChannelWidget;